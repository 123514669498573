import { useEffect } from 'react';

const useScanner = <T>(
  cb: (data: T[]) => void,
  minimalLength: number = 0,
  options: T[] = [],
  cmpCb: (a: T, b: string) => boolean,
  ignoreValues: string[] = [],
  acceptOnly: string[] = []
) => {
  const flushScanner = () => {
    window.scanning = false;
    if (window.scannerTimeout) {
      clearTimeout(window.scannerTimeout);
    }

    if (
      window.scannedText?.length >= minimalLength &&
      !ignoreValues.includes(window.scannedText) &&
      (acceptOnly.length === 0 || acceptOnly.includes(window.scannedText))
    ) {
      cb(options.filter((option) => cmpCb(option, window.scannedText)));
    }
    window.scannedText = '';
  };

  const onKeyPress = ({ key, target: { nodeName } }: any) => {
    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(nodeName.toLowerCase())) {
      return;
    }

    if (key === 'Enter') {
      flushScanner();
      return;
    }

    if (!window.scanning) {
      window.scannedText = '';
      window.scanning = true;
    }

    if (window.scanning) {
      window.scannedText += key;
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannerTimeout = setTimeout(() => {
        flushScanner();
      }, 50);
    }
  };
  useEffect(() => {
    window.addEventListener('keypress', onKeyPress);
    return () => {
      window.removeEventListener('keypress', onKeyPress);
    };
  }, []);
};

export default useScanner;
